<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCol xl="2" class="mb-3 ml-3 padding-0">
        <CButton color="info" class="btn-block" @click="addData()"><v-icon name="pencil-alt"/> Add Contact</CButton>
      </CCol>
      <CCard class="col-xl-12 padding-0">
        <CCardHeader>
            <CRow class="padding-row">
                <CCol xl="3">
                    <CRow>
                        <CCol xl="12" class="padding-0">
                            <CInput
                                placeholder="Search"
                                v-model="search"
                                size="sm"
                                class="mb-0"
                            >
                            <template #append-content>
                                <v-icon name="search" class="v-mid" v-on:click="searchData" style="cursor: pointer;"/>
                            </template>
                            </CInput>
                        </CCol>
                    </CRow>
                </CCol>
                <CCol xl="3">
                    <CRow>
                        <CCol tag="label" xl="3" class="col-form-label padding-0 pl-1">
                        Sorting:
                        </CCol>
                        <CCol xl="5" class="padding-0">
                        <CSelect
                            placeholder="Please select sorting"
                            :value.sync="sortbyval"
                            :options="sortby"
                            size="sm"
                            class="mb-0"
                        />  
                        </CCol>
                        <CCol xl="4" class="padding-0">
                        <CSelect
                            placeholder="Please select sort"
                            :value.sync="sortval"
                            :options="sort"
                            size="sm"
                            class="pl-1 mb-0"
                        />  
                        </CCol>
                    </CRow>
                </CCol>
                <CCol xl="4"></CCol>
                <CCol xl="2">
                    <CRow class="float-right">
                        <CCol tag="label" xl="8" class="col-form-label padding-0">
                        Items per page:
                        </CCol>
                        <CCol xl="4" class="padding-0">
                        <CSelect
                            :value.sync="itemperpage"
                            :options="filterperpage"
                            size="sm"
                            class="mb-0"
                        />  
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        </CCardHeader>
        <CCardBody class="" style="text-align: center; padding-top: 5px;">
            <CSpinner
                v-if="isLoading"
                color="info"
                style="width:3rem;height:3rem;"
            />
            <span v-if="!isLoading && this.contacts.length < 1">Data Not Found</span>
            <CRow v-if="this.contacts.length > 0" class="" style="text-align: left;">
                <CCol xl="4" class="padding-7" v-for="(ctc, idx) in contacts" :key="idx">
                    <CCard accent-color="info" class="dark-card">
                        <CCardBody class="padding-10 text-muted">
                            <CRow class="padding-row">
                                <CCol xl="11" class="padding-0 mb-1"><span class="font-09">{{ctc.division?ctc.division:'-'}}</span></CCol>
                                <CCol xl="1" class="padding-0" style="cursor: pointer;" @click="removeData(ctc.id)"><v-icon name="trash" class="mr-1 v-mid"/></CCol>
                                <CCol xl="8" class="padding-0">
                                    <CRow class="padding-row">
                                        <CCol xl="12" class="padding-0 mb-1"><span class="font-11" style="color: #000;">{{ctc.name?ctc.name:'-'}}</span></CCol>
                                        <CCol xl="12" class="padding-0 mb-2"><span class="font-07"><b>NRP:</b> {{ctc.nrp?ctc.nrp:'-'}}</span></CCol>
                                        <CCol xl="1" class="padding-0"><v-icon class="font-07" name="building"/></CCol>
                                        <CCol xl="11" class="padding-0"><span class="font-07">Address: {{ctc.address?ctc.address:'-'}}</span></CCol>
                                        <CCol xl="1" class="padding-0"><v-icon class="font-07" name="phone"/></CCol>
                                        <CCol xl="11" class="padding-0"><span class="font-07">Phone #: {{ctc.phone?ctc.phone:'-'}}</span></CCol>
                                    </CRow>
                                </CCol>
                                <CCol xl="4" class="padding-0 text-center">
                                    <img :src="ctc.profile_picture?ctc.profile_picture:url" class="bd-avatar-ct"/>
                                </CCol>
                                <CCol xl="12" class="padding-0 mt-3">
                                    <CButton color="success" class="float-right btn-chat-green btn-sm" @click="showChat(ctc.id)">
                                        <v-icon name="comments"/> Chat
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <CPagination
              v-if="this.contacts.length > 0"
              :active-page.sync="currentpage"
              :pages="lastpage"
              :doubleArrows="true"
              align="center"/>
        </CCardBody>
      </CCard>
    </CRow>
    <CModal
      title="Modal Delete"
      :show.sync="modalDelete"
      size="sm"
    >
      <span>Are you sure remove this contact ?</span>
      <template #header>
        <h6 class="modal-title">Remove Data</h6>
        <CButtonClose @click="modalDelete = false"/>
      </template>
      <template #footer>
        <CButton @click="modalDelete = false" color="light" class="text-left">Cancel</CButton>
        <CButton @click="destroyData(delId)" color="danger">Yes</CButton>
      </template>
    </CModal>
    <CModal
      title="Modal Form"
      :show.sync="modalForm"
      :closeOnBackdrop="false"
      size="xl"
    >
      <CCol v-if="isMLoading" xl="12" style="text-align: center;">
        <CSpinner
          color="info"
        />
      </CCol>
      <CForm v-if="!isMLoading">
        <div class="form-group" role="group">
          <label>User</label>
          <multiselect
            @input="changeUnlist"
            v-model="value_users"
            :options="userslist"
            track-by="value"
            label="label"
            :multiple="true"
            placeholder="Choose User"
            :close-on-select="false"
            :clear-on-select="false">
          </multiselect>
          <small class="form-text text-muted w-100">{{errors.contacts}}</small>
        </div>
      </CForm>
      <template #header>
        <h6 class="modal-title">Add Data</h6>
        <CButtonClose @click="modalForm = false"/>
      </template>
      <template #footer>
        <CButton @click="modalForm = false" color="light" class="text-left">Cancel</CButton>
        <CButton @click="postData()" color="info">Save</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Contact',
  components: {},
  data () {
    return {
        isLoading: false, isMLoading: false,
        modalForm: false, modalDelete: false,
        delId: '',
        url: 'img/avatars/profile_img.svg',
        tableconfig: {
            search: '',
            totalperpage: 10,
            pagenumber: 1,
            sortingid: 'users.name',
            sorting: 'asc',
            _method: 'GET'
        },
        form: {
          contacts: []
        },
        errors: {
          contacts: '',
          contact: ''
        },
        contacts: [],
        userslist: [],
        value_users: [],
        sortby: [
          {label: 'NRP', value: 'nrp'}, 
          {label: 'Name', value: 'users.name'}, 
          {label: 'Division', value: 'divisions.name'},
          {label: 'Phone', value: 'phone'}
        ],
        sort: [
          {label: 'Asc', value: 'asc'}, 
          {label: 'Desc', value: 'desc'}
        ],
        itemperpage: 10, lastpage: 1, currentpage: 1,
        sortbyval: 'name', sortval: 'asc', search: '',
        filterperpage: [10, 15, 25, 50],
    }
  },
  watch: {
    currentpage: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.tableconfig.pagenumber = this.currentpage
          this.reRender() 
        }
      }
    },
    itemperpage: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.tableconfig.totalperpage = this.itemperpage
          this.reRender() 
        }
      }
    },
    sortbyval: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.tableconfig.sortingid = this.sortbyval
          this.reRender() 
        }
      }
    },
    sortval: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.tableconfig.sorting = this.sortval
          this.reRender() 
        }
      }
    },
    modalForm: {
      handler: function (val, oldVal) {
        if(!val){
          this.value_users = []
          this.form = { contacts: [] }
          this.errors = { contacts: '', contact: '' }
        }
      }
    },
    modalDelete: {
      handler: function (val, oldVal) {
        if(!val){ this.delId = '' }
      }
    }
  },
  async created() {
    await this.reRender()
  },
  methods: {
    ...mapActions('contact', ['indexCTC', 'storeCTC', 'destroyCTC', 'unlistCTC']),
    reRender(){
      this.isLoading = true
      this.contacts = []
      this.indexCTC(this.tableconfig).then((response) => {
        this.contacts = response.data.length > 0 ? response.data : []
        this.currentpage = response.currentpage
        this.lastpage = response.lastpage
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    searchData() {
        this.tableconfig.search = this.search
        this.reRender()
    },
    addData() {
      this.getUnlist()
      this.modalForm = true
    },
    removeData(uuid){
      this.modalDelete = true
      this.delId = uuid
    },
    postData(){
      if(this.form.contacts.length>0) {
        this.isMLoading = true
        this.storeCTC(this.form).then((response) => {
          this.reRender()
          this.isMLoading = false
          this.modalForm = false
        }).catch((error) => {
          this.flashMessage.error({ title: 'Error', message: error.statusText })
          this.getError(error.data)
          this.isMLoading = false
        })
      } else {
        this.flashMessage.error({ title: 'Error', message: 'User Not Found' })
      }
    },
    destroyData(uuid){
      var delform = {
        contact: uuid
      }
      this.modalDelete = false
      this.destroyCTC(delform).then((response) => {
        this.reRender()
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isLoading = false
      })
    },
    async getUnlist(){
        this.isMLoading = true
        this.userslist = []
        await this.unlistCTC().then((response) => {
            response.forEach((item, index) => {
                this.userslist[index] = { value: item.id, label: item.name }
            })
            this.isMLoading = false
        }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error })
            this.isMLoading = false
        })
    },
    changeUnlist(val) {
      this.value_users = val
      this.form.contacts = val.map(function (obj) {
        return obj.value
      })
    },
    showChat(id) {
        this.$router.push({name: 'Chat Group', params: { roomid: id }})
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errors.contacts = data.errors.contacts ? data.errors.contacts[0] : ''
        this.errors.contact = data.errors.contact ? data.errors.contact[0] : ''
        if(this.errors.contact) {
          this.flashMessage.error({ title: 'Error', message: this.errors.contact })
        }
      }}
    }
  }
}
</script>